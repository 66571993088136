import React from 'react'
import Nav from '../Home/Nav'

function AdHome() {
  return (
    <div>
      <Nav/>
    </div>
  )
}

export default AdHome